import { CxOptions } from "class-variance-authority";
import { cn } from "./cn";
import { withProps } from "./withProps";
import React from "react";

export function withCn<T extends React.ComponentType<any>>(
  Component: T,
  ...inputs: CxOptions
) {
  return withProps(Component, { className: cn(inputs) } as any);
}
